import {createStore} from 'redux';
import {createReducer, createAction} from 'redux-act';

export type Store = {
    call?: {
        isHold?: boolean;
        isMuted?: boolean;
        isEnded?: boolean;
        isOutgoing?: boolean;
    };
    app?: {
        permissions?: any;
        env?: string;
    };
    mockPhone?: {
        phone: string;
    };
    callInfo?: {
        startTime?: number;
        endTime?: number;
        phone?: string;
        queue?: string;
        chainId?: string;
        commutationId?: string;
        originalDN?: string;
        platformId?: string;
        direction?: string;
        accessToken?: string;
    };
};

const initialState: Store = {
    callInfo: {
        startTime: Date.now(),
    },
    call: {
        isEnded: false,
    },
};

window.serverData = {};

const setAppInfo = createAction<Store>('set/info');
const setPermissonsInfo = createAction<Record<string, any>>('set/permissions');

export const actions = {
    setAppInfo,
    setPermissonsInfo,
};

const reducer = createReducer<Store>({
    [`${actions.setAppInfo}`]: (state, payload) => ({
        mockPhone: {
            phone: payload.callInfo?.phone,
        },
        app: {
            ...state.app,
            ...payload.app,
        },
        call: {
            ...state.call,
            ...payload.call,
        },
        callInfo: {
            ...state.callInfo,
            ...payload.callInfo,
        },
    }),
    [`${actions.setPermissonsInfo}`]: (state, payload) => ({
        ...state,
        app: {
            ...state.app,
            ...payload,
        },
    }),
}, initialState);

export const store = createStore(reducer);
