import {loadAudios} from '#utils/playAudio';

export const setStartupData = () => {
    loadAudios();
    window.serverData.support_options = {
        api_urls: {
            // Antifraud data:
            antifraud: 'cc/v1/fury-rtxaron/v1/antifraud-flags',
            antifraud_resolution: 'v1/taxi/driver/promocode/check',
            // Driver data:
            driver_profile: 'cc/v1/callcenter-support-contractors/v2/driver_card',
            driver_meta: 'cc/v1/taxi-support-meta/v1/enrich_driver_meta',
            driver_license: 'cc/v1/personal/v1/driver_licenses/retrieve',
            // Order data:
            order: 'cc/v1/admin-orders/v1/order/',
            common_meta: 'cc/v1/taxi-support-meta/v1/enrich_common_meta',
            phones: 'cc/v1/personal/v1/phones/retrieve',
            forwading: 'cc/v1/admin-orders/v1/cc-forwarding/',
            // B2B client data:
            client_contracts: 'cc/v1/corp-clients/contracts',
            client_location: 'cc/v1/corp-clients/location',
            b2b_tier: 'cc/v1/callcenter-support-contractors/v1/get_tier',
            // Content data:
            macadmin_themes: 'cc/v1/macadmin/v1/themes',
        },
    };
};
