/* eslint-disable quote-props */

/**
 * fetch успешно выполнен, но код отличен от 200
 */
export class ServerError extends Error {
    readonly req?: RequestInit;

    readonly res?: Response;

    readonly body?: any;

    readonly status?: number;

    readonly errorText?: string;

    readonly retryFn?: VoidFunction;

    readonly url?: string;

    code?: string;

    constructor(
        message: string,
        {res, body, req, url, retryFn}: {status: number; res: Response | undefined; body: any; req: RequestInit | undefined; url: string; retryFn?: VoidFunction},
    ) {
        super(message);

        this.req = req;
        this.res = res;
        this.body = body;
        this.url = url;
        this.retryFn = retryFn;

        if (res) {
            this.status = res.status;
        }

        // часть ручек бекенда отвечает в таком формате
        if (body) {
            if (body.error && body.error.text) {
                this.errorText = body.error.text;
            }

            if (body?.message) {
                this.errorText = body.message;
            }
        }

        const capture = (Error as any).captureStackTrace;
        if (capture instanceof Function) {
            capture(this, ServerError);
        } else {
            this.stack = new Error().stack;
        }
    }
}

export enum OrderValidationErrors {
    InvalidPreOrder = 'INVALID_PRE_ORDER',
    PriceChanged = 'PRICE_CHANGED',
    TooManyConcurrentOrders = 'TOO_MANY_CONCURRENT_ORDERS',
    NoOffer = 'NO_OFFER',
    NoUser = 'NO_USER',
    UserBlocked = 'USER_BLOCKED',
    OperatorHasNoRights = 'OPERATOR_HAS_NO_RIGHTS',
    ChangeDestinationsError = 'CHANGE_DESTINATION_ERROR',
    InvalidFirstPoint = 'INVALID_FIRST_POINT',
    InvalidDestinationPoint = 'INVALID_DESTINATION_POINT',
    RequiredCostCenters = 'REQUIRED_COST_CENTERS',
    CashDisabled = 'CASH_DISABLED',
}

export enum EstimateErrorType {
    CantConstructRoute = 'CANT_CONSTRUCT_ROUTE',
    UserBlocked = 'USER_BLOCKED',
    BadRoute = 'BAD_ROUTE',
    InternetOff = 'INTERNET_OFF',
    NearestZoneNotFound = 'NEAREST_ZONE_NOT_FOUND',
    WrongPayMethod = 'WRONG_PAY_METHOD',
}

export class OrderValidationError extends Error {
    static TYPES = OrderValidationErrors;

    code: OrderValidationErrors;

    constructor(code: OrderValidationErrors) {
        super(code);

        this.code = code;
    }
}
