import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';

import {AUDIOS, playAudio} from '#utils/playAudio';
import {Store} from 'reducers';

export const useSoundOnHold = () => {
    const isHold = useSelector<Store>(state => state.call.isHold);
    const first = useRef<NodeJS.Timeout>();
    const second = useRef<NodeJS.Timeout>();
    const third = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (isHold) {
            first.current = setTimeout(() => {
                playAudio(AUDIOS.HOLD);
            }, 45000);
            second.current = setTimeout(() => {
                playAudio(AUDIOS.HOLD);
            }, 60000);
            third.current = setTimeout(() => {
                playAudio(AUDIOS.HOLD);
            }, 60000 + 1000);
        }
        if (!isHold) {
            window.clearTimeout(first.current);
            window.clearTimeout(second.current);
            window.clearTimeout(third.current);
        }
    }, [isHold]);
};
