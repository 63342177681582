import memoize from 'lodash/memoize';

import {sipPhoneOptions} from '../bunker';

const audioWaitTimeoutMs = 500;

// NOTE: этот урл не должен изменяться часто. Если будет часто - положить в конфиг.
const urlStatic = 'https://yastatic.net/s3/taxi-front/callcenter-beta/audio/';
export const AUDIOS = {
    TIMER: 'timer.mp3',
    TIMERDOUBLE: 'timer-double.mp3',
    HOLD: 'hold.mp3',
    POSTCALL: 'postcall.mp3',
};

interface AudioOptions {
    loop?: boolean;
    volume?: number;
}

const AUDIO_OPTIONS_DEFAULT = {
    loop: false,
    volume: sipPhoneOptions.volume,
} satisfies AudioOptions;

const getAudio = memoize((url: string) => {
    return new Audio(url);
});

// CALLCENTERFRONT-781: аудиофайлы надо предзагрузить, т.к. у операторов может быть плохой интернет
// и в момент звонка аудиофайл не успеет прилететь, оператор не услышит входящий и пропустит звонок
export const loadAudios = () => {
    const promises = Object.values(AUDIOS).map(filename => {
        const audio = getAudio(`${urlStatic}${filename}`);
        audio.load();
        return new Promise((resolve, reject) => {
            audio.addEventListener('canplaythrough', resolve);
            audio.addEventListener('error', reject);
        });
    });
    return Promise.allSettled(promises);
};

export const playAudio = (filename: string, options: AudioOptions = {}) => {
    const opts = {...AUDIO_OPTIONS_DEFAULT, ...options};

    const audio = getAudio(`${urlStatic}${filename}`);
    audio.volume = opts.volume;
    audio.loop = opts.loop;

    // Если аудио не загрузилось через n мс - отменить его воспроизведение
    const timeoutCancelPlaying = setTimeout(() => {
        if (audio.readyState !== HTMLMediaElement.HAVE_ENOUGH_DATA) {
            audio.pause();
        }
    }, audioWaitTimeoutMs);

    audio.oncanplaythrough = () => {
        clearTimeout(timeoutCancelPlaying);
    };

    audio.play();

    return () => {
        audio.pause();
        audio.currentTime = 0;
    };
};
