import React, {memo} from 'react';

import {Props} from './types';

const ErrorMessage: Props = ({code: _code}) => {
    return (
        <div>
            Unexpected error in the form, please contact technical support
        </div>
    );
};

export default memo(ErrorMessage);
