import {Configs1} from './configs1';
import {Configs3} from './configs3';
import {Experiments3} from './experiments3';

export interface ServerData {
    isEurope: boolean;
    lang: string;
    env: 'development' | 'testing' | 'production' | 'unstable';
    version: string;
    yaEnv: string;
    login: string;
    uid: string;
    passportHost: string;
    project: string;
    // массив аккаунов метрики
    accounts: number[];
    authproxyHost: string;
    support_options: {
        api_urls: {
            // Antifraud data:
            antifraud: string;
            antifraud_resolution: string;
            // Driver data:
            driver_profile: string;
            driver_license: string;
            driver_meta: string;
            // Order data:
            order: string;
            common_meta: string;
            phones: string;
            forwading: string;
            // B2B client data:
            client_contracts: string;
            client_location: string;
            b2b_tier: string;
            // Content data:
            macadmin_themes: string;
        };
    };
    backendConfigs: Configs1;
    experiments3?: Experiments3;
    configs3?: Configs3;
}

const server = window.serverData as ServerData;

const sipPhoneOptions = {
    answerTimer: 300,
    volume: 0.7,
};

export {
    server,
    sipPhoneOptions,
};
