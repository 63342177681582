import Logger from '@yandex-int/error-counter/dist/logger';

import {server} from '../bunker';

const logger = server.isEurope ? null : new Logger();
logger?.initErrors({
    project: server.project,
    env: server.env === 'unstable' ? 'development' : server.env,
    version: server.version,
    yandexuid: server.uid,
});

const loggerWrapper: Pick<Logger, 'logError'> = {
    logError: (...args) => {
        logger?.logError(...args);
    },
};

export default loggerWrapper;
