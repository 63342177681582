export enum Languages {
    Ru = 'ru',
    En = 'en',
    Ar = 'ar',
    Fr = 'fr',
    Es = 'es-la',
}

export enum DefaultLanguages {
    Ru = 'ru',
    En = 'en',
}

export const DEFAULT_LANGUAGE: Languages = Languages.Ru;
export const FALLBACK_LANGUAGES = ['en'];
export const KEYSETS = {
    ORIGIN: 'callcenter-support-forms',
    ADMIN: 'callcenter-admin',
    FRONTEND: 'callcenter-frontend',
};
