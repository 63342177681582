import {server} from '../../bunker';
import {createRequestIdHeader} from '../../utils/createRequestHeader';
import {serverErrorsPredicate, withRetry} from '../../utils/withRetry';

import {toClient} from './toClient';
import {PermissionsResponse} from './types';

const fetchWithRetry = withRetry(serverErrorsPredicate, 1, 0);

const fetchPermissions = fetchWithRetry(() => fetch(
    `${server.authproxyHost}/cc/v1/callcenter-staff/v2/user-info/`,
    {
        credentials: 'include',
        headers: createRequestIdHeader(),
    },
)
    .then(async res => {
        const data: PermissionsResponse = await res.json();
        const header = res.headers.get('Date');
        const now = Date.now();
        const timeDiffBetweenClientAndServer = (header ? Date.parse(header) : now) - now || 0;

        return {...data, timeDiffBetweenClientAndServer};
    }));

export default () => fetchPermissions().then(toClient);
