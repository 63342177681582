import {nanoid} from 'nanoid';
import React, {FC, Suspense, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Route, Routes, Navigate} from 'react-router';

import {Spinner} from '#blocks/spinner';
import {fetchSaveFeedbackWithoutImage} from '#mapper/report/fetch';
import {CreateTicketsResponse} from '#mapper/support/support-task/types';
import {getCallAsteriskId, getChainId, getCommutationId} from '#redux/call-info/selectors';
import {usePermissionsQuery} from 'hooks/usePermissions';
import {useTypedParams} from 'hooks/useQueryParams';
import {useSoundOnHold} from 'hooks/useSoundOnHold';
import {Store} from 'reducers';

const SupportForm = React.lazy(() => import('../../../SupportForm/src/components2/support/support-form/SupportForm'));
const SupportDriverForm = React.lazy(() => import('../../../SupportForm/src/components2/support/support-driver-form/SupportDriverForm'));

const App: FC = () => {
    const {isLoaded: permissionsLoaded} = usePermissionsQuery();
    const chainId = useSelector(getChainId);
    const callAsteriskId = useSelector(getCallAsteriskId);
    const commutationId = useSelector(getCommutationId);
    const isCallEnded = useSelector<Store>(state => state.call.isEnded);
    const isInIframe = window.self !== window.top;
    const {project} = useTypedParams();

    useSoundOnHold();

    const onSubmit = useCallback(() => {
        window.parent.postMessage(JSON.stringify({message: 'sent', name: 'custom'}), '*');
    }, []);

    const handleSubmitDriver = useCallback((data?: CreateTicketsResponse) => {
        if (data?.hasNegativeDriver) {
            fetchSaveFeedbackWithoutImage({
                feedback_id: nanoid(32),
                ...chainId && {
                    call_info: {
                        call_guid: chainId,
                        call_id: callAsteriskId,
                        commutation_id: commutationId,
                    },
                },
                feedback: {
                    type: 'complaint_aggressive_driver',
                },
                project,
            });
        }
    }, [chainId, callAsteriskId, project, commutationId]);

    if (!permissionsLoaded) {
        return null;
    }

    return (
        <Suspense fallback={<Spinner/>}>
            <Routes>
                <Route path="/" element={<Navigate to={'/users'}/>}/>
                <Route
                    path={'/users'}
                    element={<SupportForm disabled={!isCallEnded && isInIframe} onSubmit={onSubmit}/>}
                />
                <Route
                    path={'/drivers'}
                    element={(
                        <SupportDriverForm
                            isIntegaratedForm={isInIframe}
                            hasActiveCall={!isCallEnded}
                            onSubmit={handleSubmitDriver}
                            onFormClose={onSubmit}
                        />
                    )}
                />
            </Routes>
        </Suspense>
    );
};

export default App;
