import {ServerError} from './errors';

const delay = (timeout: number) => new Promise(resolve => setTimeout(resolve, timeout));

type PromiseFunction<Args extends any[], T> = (...args: Args) => Promise<T>;
type PredicateFunction = (value: any) => boolean;

export function wrapWithRetry<T = unknown, Args extends any[] = any[]>(
    fn: PromiseFunction<Args, T>,
    predicate: PredicateFunction,
    maxCount: number = 0,
    timeout: number = 0,
): PromiseFunction<Args, T> {
    const retry = async (count: number, retryFn: VoidFunction | null, ...args: Args): Promise<any> => {
        try {
            const result = retryFn
                ? await retryFn()
                : await fn(...args);
            return result;
        } catch (error) {
            if (predicate(error) && count < maxCount) {
                if (timeout) {
                    return delay(timeout).then(() => retry(count + 1, error.retryFn, ...args));
                }
                return retry(count + 1, error.retryFn, ...args);
            }
            throw error;
        }
    };
    return (...args) => retry(0, null, ...args);
}

type WrappedPromise = <T = unknown, Args extends any[] = any[]>(fn: PromiseFunction<Args, T>) => PromiseFunction<Args, T>;

export const withRetry = (
    predicate: PredicateFunction,
    maxCount: number = 0,
    timeout: number = 0,
): WrappedPromise => fn => {
    return wrapWithRetry(fn, predicate, maxCount, timeout);
};

export const serverErrorsPredicate = (error: ServerError) => {
    return (error?.status && error?.status >= 500) || false;
};
