import isEqual from 'lodash/isEqual';
import {DependencyList, useEffect, useState} from 'react';

// https://github.yandex-team.ru/taxi/tariff-editor/blob/676ae2202b8306fe3fae32951eaa93165e7613ba/packages/core/src/components/hooks/use-equal-memo/index.ts
export function useEqualMemo<T>(fn: () => T, args: DependencyList = []): T {
    const [memoized, memo] = useState(fn());

    useEffect(() => {
        const data = fn();

        if (!isEqual(memoized, data)) {
            memo(data);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoized, fn, ...args]);

    return memoized;
}
