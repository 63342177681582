import {RequestFunction, useRequest} from '@yandex-taxi/react-query-hooks';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {useBinaryState} from '#hooks/use-binary-state';
import fetchPermissions, {PermissionsData} from '#mapper/permissions';
import {actions} from 'reducers';

export const usePermissionsQuery = () => {
    const dispatch = useDispatch();
    const [isLoaded, setLoaded] = useBinaryState(false);

    const [request] = useState<RequestFunction>(() => () => fetchPermissions());

    const {loading, data, error} = useRequest<PermissionsData>(request);

    useEffect(() => {
        if (data) {
            dispatch(actions.setPermissonsInfo({
                permissions: data.permissions,
                prefixPermissions: data.prefixPermissions,
                timeDiffBetweenClientAndServer: data.timeDiffBetweenClientAndServer,
                projects: data.projects,
            }));
            setLoaded();
        }
    }, [data, setLoaded, dispatch]);

    useEffect(() => {
        if (error) {
            setLoaded();
        }
    }, [error, setLoaded]);

    return {loading, data, error, isLoaded};
};
