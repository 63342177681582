import {server} from '../bunker';

import {createRequestIdHeader} from './createRequestHeader';

const KEY = 'CC_CSRF_TOKEN';
const VALID = 'CC_CSRF_TOKEN_VALID_TO';

interface TokenResponse {
    sk: string;
    'max-age-seconds': number;
}

// Если до окончания действия токена осталось 5 или меньше минут, то мы заранее его обновим
const UPDATE_TOKEN_TIMER = 5 * 60 * 1000;

export function requestCsrf() {
    return fetch(`${server.authproxyHost}/csrf_token`, {
        credentials: 'include',
        headers: createRequestIdHeader(),
    })
        .then(resp => resp.json())
        .then((resp: TokenResponse) => {
            window.sessionStorage.setItem(KEY, resp.sk);
            window.sessionStorage.setItem(VALID, JSON.stringify(Date.now() + resp['max-age-seconds'] * 1000));

            return resp.sk;
        });
}

function isValidToken(validTo: string | null) {
    return validTo && Number(validTo) - UPDATE_TOKEN_TIMER > Date.now();
}

export async function getToken(): Promise<string> {
    const token = window.sessionStorage.getItem(KEY);
    const validTo = window.sessionStorage.getItem(VALID);

    if (token && isValidToken(validTo)) {
        return token;
    }

    return requestCsrf();
}
