import {useCallback, useState} from 'react';

export const useBinaryState = (initialState = false): [boolean, VoidFunction, VoidFunction] => {
    const [state, setState] = useState(initialState);
    const setTrue = useCallback<() => void>(() => setState(true), []);
    const setFalse = useCallback<() => void>(() => setState(false), []);

    return [
        state,
        setTrue,
        setFalse,
    ];
};
