import {create, DefaultClient, Options, RequestMethod, SourceID} from '@yandex-taxi/js-integration-api';
import {isNil, omitBy} from 'lodash';

import {server} from '#bunker';
import logger from '#logger';
import {createRequestIdHeader} from '#utils/createRequestHeader';
import {getToken} from '#utils/csrf';
import {ServerError} from '#utils/errors';

import {PassportResponse} from './types';

const MAX_RETRY_COUNT = 2;

function isJson(res: Response): boolean | undefined {
    const contentType = res.headers.get('content-type');

    return contentType?.includes('application/json');
}

async function getJson(res: Response): Promise<any> {
    if (isJson(res)) {
        // бекенд может отдавать 500 и пустой ответ с заголовками
        // поэтому будем игнорировать ошибки парсинга json
        if (res.status >= 500) {
            try {
                return await res.json();
            } catch (err) {
                return;
            }
        }

        return res.json();
    }

    return res.text();
}

const updatePassportCookie = async (): Promise<PassportResponse | undefined> => {
    try {
        const response = await fetch(`${server.passportHost}/auth/update`, {
            credentials: 'include',
        });

        return await getJson(response);
    } catch (e) {
        console.error(e); // eslint-disable-line no-console
    }
};

export async function request<Res = any>(url: string, params: Partial<Options> & {withCsrf?: boolean; with201Created?: boolean; with204NoContent?: boolean}, retryCount = 0): Promise<Res> {
    if (process.env.NODE_ENV === 'testing') {
        throw new Error('Во время выполнения тестов происходит попытка сделать реальный запрос через fetch');
    }

    const {method, with201Created, with204NoContent} = params;

    const headers = omitBy({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': server.lang,
        'x-csrf-token': params.withCsrf ? await getToken() : undefined,
        ...!params.headers?.['X-CC-Operation-ID'] && createRequestIdHeader(),
        ...params.headers,
    }, isNil);

    if (params.body instanceof FormData) {
        delete headers['Content-Type'];
    }

    const options = {
        method,
        headers,
        credentials: params.withCsrf ? 'include' : 'same-origin',
        cache: 'no-cache',
        // TODO обновить тайпинги в js-int-api и обновить сам js-in-api
        ...[RequestMethod.POST, 'PUT' as RequestMethod].includes(method ?? RequestMethod.GET) && {
            body: params.body instanceof FormData ? params.body : JSON.stringify(params.body),
        },
    };

    let res: Response;
    let body: any = null;

    try {
        res = await fetch(server.authproxyHost + url, options as RequestInit);
        if (res.status !== 204) {
            body = await getJson(res);
        }
    } catch (e) {
        // эмулируем ответ в случае если по каким то причинам не получилось достучаться до сервера
        res = {
            status: 503, // 503 Service Unavailable («сервис недоступен»)
            statusText: e.message || 'Service Unavailable',
            url,
        } as Response;
    }

    if (res.status === 403 && retryCount < MAX_RETRY_COUNT) {
        const passportResponse = await updatePassportCookie();

        if (passportResponse && passportResponse.status === 'ok') {
            return request(url, params, retryCount + 1);
        }
    }

    const allowStatuses = [200];
    if (with201Created) {
        allowStatuses.push(201);
    }
    if (with204NoContent) {
        allowStatuses.push(204);
    }

    if (!(allowStatuses.includes(res.status))) {
        const error = new ServerError('Ошибка запроса', {
            url,
            res,
            body,
            status: res.status,
            req: options as RequestInit,
            retryFn: () => request(url, {...params, headers}),
        });

        logger.logError(
            {
                message: `Ошибка запроса в API: url - ${url}`,
                additional: {
                    url,
                    error,
                },
            },
            error,
        );

        throw error;
    }

    return body;
}

const META_SOURCE_ID = {
    sourceId: SourceID.CALL_CENTER,
};

export const trainerApi = create<DefaultClient>({
    prefix: `${server.authproxyHost}/cc/v1/callcenter-exams/form/v1`,
    meta: META_SOURCE_ID,
    client: (url, options) => request(url, {...options, withCsrf: true}),
});

export const authproxyApi = create<DefaultClient>({
    prefix: server.authproxyHost,
    meta: META_SOURCE_ID,
    client: (url, options) => request(url, {...options, withCsrf: true}),
});
