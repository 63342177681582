import {PermissionsData, PermissionsResponse} from './types';

// Превращаем массивы вычисляемых пермишнов и массив обычных пермишенов в
// объект, чтобы проверять наличие пермишны за O(1)
// Также создаем префиксные пермишны, которые получаем из вычисляемых пермишнов, чтобы за O(1) проверять
// существование хотя бы одного попроектного пермишна с таким названием
export const toClient = (response: PermissionsResponse): PermissionsData => {
    const data: Record<string, boolean> = {};
    const prefixPermissions: Record<string, boolean> = {};

    const {permissions, evaluated_permissions} = response;

    permissions.forEach(permission => {
        data[permission] = true;
        prefixPermissions[permission] = true;
    });
    evaluated_permissions.forEach(({rule_name, rule_value}) => {
        data[`${rule_name}__${rule_value}`] = true;
        prefixPermissions[rule_name.replace('_in_project', '')] = true;
    });

    return {
        permissions: data,
        prefixPermissions,
        timeDiffBetweenClientAndServer: response.timeDiffBetweenClientAndServer,
        projects: response.projects,
    };
};
