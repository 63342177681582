// Обрезает в очереди сабкластер - по сути получение метаочереди из очереди
export const getQueueWithoutSubcluster = (queue?: string) => {
    if (!queue) {
        return;
    }

    const result = (/on_\d/).exec(queue);

    if (!result) {
        return queue;
    }

    return queue.slice(0, result.index - 1);
};
