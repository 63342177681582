import React, {FC} from 'react';

import Forms from 'components/App/components/Content';
import {useSetupForm} from 'hooks/useSetup';
import 'amber-blocks/stylus/clear.styl';
import 'amber-blocks/font/font.styl';

const App: FC = () => {
    const {isReady} = useSetupForm();

    if (!isReady) {
        return null;
    }

    return (
        <Forms/>
    );
};

export default App;
