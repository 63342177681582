import {parse, IParseOptions} from 'qs';
import {useLocation} from 'react-router-dom';

import {useEqualMemo} from '../useEqualMemo';

export function objectEntries<T extends object, R extends keyof T>(obj: T) {
    return Object.entries(obj) as Array<[R, T[R]]>;
}

export const QS_PARSE_OPTIONS_DEFAULT: IParseOptions = {
    ignoreQueryPrefix: true,
    arrayLimit: 99,
};

type ParserFunction<T = any> = (v?: string) => T;
export type ParamParsers<P> = {
    [K in keyof P]: ParserFunction<P[K]>;
};

export interface Options<P> {
    queriesParser: (params: P) => P;
}

export const useParams = <P>(
    paramParsers: ParamParsers<P>,
    options?: Options<P>,
) => {
    const {queriesParser} = options || {};
    const location = useLocation();
    const params = parse(location.search, QS_PARSE_OPTIONS_DEFAULT) as any as P;

    const parsedParams = !queriesParser
        ? params
        : queriesParser(params);

    return useEqualMemo(() => {
        return objectEntries(paramParsers).reduce<P>((memo, [key, parser]) => {
            const value = parser(parsedParams[key] as any as string);

            return {
                ...memo,
                ...(value !== undefined ? {[key]: value} : {}),
            };
        }, {} as P);
    }, [parsedParams]);
};

export function stringParser<T extends string = string>(defaultValue: T): ParserFunction<T>;
export function stringParser<T extends string = string>(defaultValue?: T): ParserFunction<Undefinable<T>>;
export function stringParser<T extends string = string>(defaultValue?: T): ParserFunction<Undefinable<T>> {
    return (value): T | undefined => {
        if (typeof value === 'string') {
            return value as T;
        }
        return defaultValue;
    };
}

export function numberParser(defaultValue: number): ParserFunction<number>;
export function numberParser(defaultValue?: number): ParserFunction<Undefinable<number>>;
export function numberParser(defaultValue?: number): ParserFunction<Undefinable<number>> {
    return (value): number | undefined => {
        const numberValue = Number(value);

        if (value && !Number.isNaN(numberValue)) {
            return numberValue;
        }
        return defaultValue;
    };
}

export function arrayParser<T extends string = string>(defaultValue: T[]): ParserFunction<T[]>;
export function arrayParser<T extends string = string>(defaultValue?: T[]): ParserFunction<Undefinable<T[]>>;
export function arrayParser<T extends string = string>(defaultValue?: T[]): ParserFunction<Undefinable<T[]>> {
    return (value): T[] | undefined => {
        if (Array.isArray(value)) {
            return value as T[];
        }
        if (value === undefined) {
            return defaultValue;
        }
        return [value as T];
    };
}

export function arrayNumberParser(defaultValue: number[]): ParserFunction<number[]>;
export function arrayNumberParser(defaultValue?: number[]): ParserFunction<Undefinable<number[]>>;
export function arrayNumberParser(defaultValue?: number[]): ParserFunction<Undefinable<number[]>> {
    return (value): number[] | undefined => {
        if (typeof value === 'string') {
            const numArray = value.split(',').map(item => Number(item));

            return numArray;
        }
        if (value === undefined) {
            return defaultValue;
        }
        return [];
    };
}

export function arrayStringParser(defaultValue: string[]): ParserFunction<string[]>;
export function arrayStringParser(defaultValue?: string[]): ParserFunction<Undefinable<string[]>>;
export function arrayStringParser(defaultValue?: string[]): ParserFunction<Undefinable<string[]>> {
    return (value): string[] | undefined => {
        if (typeof value === 'string') {
            const numArray = value.split(',');

            return numArray;
        }
        if (value === undefined) {
            return defaultValue;
        }
        return [];
    };
}

export function boolParser(defaultValue: boolean): ParserFunction<boolean>;
export function boolParser(defaultValue?: boolean): ParserFunction<Undefinable<boolean>>;
export function boolParser(defaultValue?: boolean): ParserFunction<Undefinable<boolean>> {
    return (value): boolean | undefined => {
        if (typeof value === 'string') {
            if (value === 'true') {
                return true;
            }
            if (value === 'false') {
                return false;
            }
        }
        return defaultValue;
    };
}

export type CallformRouteParams = {
    phone?: string;
    lang?: string;
    project?: string;
};

export const paramParsers: ParamParsers<CallformRouteParams> = {
    phone: stringParser(),
    lang: stringParser(),
    project: stringParser(),
};

const paramsOptions: Options<CallformRouteParams> = {
    queriesParser: items => {
        return {
            ...items,
        };
    },
};

export const useTypedParams = () => {
    return useParams<CallformRouteParams>(
        paramParsers,
        paramsOptions,
    );
};
