import {AxiosError} from 'axios';
import React, {Component, ErrorInfo, memo} from 'react';

import ErrorMessage from './components/ErrorMessage';
import {Props, State} from './types';

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {hasError: false, error: undefined};
    }

    static getDerivedStateFromError(error: Error) {
        return {
            hasError: true,
            error,
        };
    }

    componentDidCatch(error: Error, {componentStack}: ErrorInfo) {
        const errorType = error instanceof AxiosError ? 'AxiosError' : 'ErrorBoundary';

        console.error({
            message: error.message,
            type: errorType,
            additional: {
                componentStack,
            },
        }, error);
    }

    render() {
        if (this.state.error && this.state.error instanceof AxiosError) {
            return <ErrorMessage code={this.state.error?.request.status}/>;
        }

        if (this.state.error) {
            return <ErrorMessage/>;
        }

        return this.props.children;
    }
}

export default memo(ErrorBoundary);
