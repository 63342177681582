import {RequestProvider} from '@yandex-taxi/react-query-hooks';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';

import {store} from 'reducers';
import {setStartupData} from 'utils/setStartupData';

import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';
import './index.css';

setStartupData();

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Provider store={store}>
                <RequestProvider>
                    <Router>
                        <App/>
                    </Router>
                </RequestProvider>
            </Provider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
);
