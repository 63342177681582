import b_ from 'b_';
import React from 'react';

import './Spinner.styl';

interface Props {
    size?: string;
    position?: string;
    className?: string;
}

const b = b_.with('Spinner');

const Spinner: React.SFC<Props> = props => {
    const {
        size = false,
        position = false,
        className,
    } = props;
    const spinnerClassName = [b({size, position}), className]
        .filter(Boolean)
        .join(' ');

    return (
        <div className={spinnerClassName}/>
    );
};

export default Spinner;
