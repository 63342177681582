import {identity as mergeBody} from 'lodash';

import {authproxyApi} from '../../api/src/fetch';
import {FeedbackInfo} from '../../api-types/uservices/services/callcenter-qa/api/api';
import {server} from '../../bunker';
import {createRequestIdHeader} from '../../utils/createRequestHeader';

export const fetchSaveFeedbackWithoutImage = authproxyApi.__request<FeedbackInfo, unknown>('cc/v1/callcenter-qa/v1/feedback/save', {
    mergeBody,
});

export const fetchSaveFeedback = async (info: FeedbackInfo, image?: Blob, sip_log?: Blob) => {
    if (image && sip_log) {
        try {
            const formData = new FormData();
            formData.append('image', image);
            formData.append('sip_log', sip_log);
            const body = await fetch(`${server.authproxyHost}/cc/v1/callcenter-qa/v1/binary/save`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
                headers: createRequestIdHeader(),
            }).then(res => res.json());

            info.binary_data = [];

            if (body?.sip_log) {
                info.binary_data.push({
                    type: 'sip_log',
                    mds_link: body.sip_log.mds_link,
                });
            }

            if (body?.image) {
                info.binary_data.push({
                    type: 'image',
                    mds_link: body.image.mds_link,
                });
            }
        } catch (e) {
            // pass
        }
    }

    return fetchSaveFeedbackWithoutImage(info);
};
