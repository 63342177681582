import {createSelector} from 'reselect';

import {CallInfoState} from '#redux/call-info/types';
import {getQueueWithoutSubcluster} from '#utils/getQueueWithoutSubcluster';

import {SharedState} from '../types';

export const getCallInfo = ({callInfo}: SharedState): CallInfoState | null => callInfo;
export const getQueue = ({callInfo}: SharedState): string | undefined => callInfo?.queue;
export const getChainId = ({callInfo}: SharedState) => callInfo?.chainId;
export const getCallTimeStart = ({callInfo}: SharedState) => callInfo?.startTime;
export const getCallEndTime = ({callInfo}: SharedState) => callInfo?.endTime;
export const getOriginalDN = ({callInfo}: SharedState): string | undefined => callInfo?.originalDN;
export const getOriginalPhone = ({callInfo}: SharedState): string | undefined => callInfo?.phone;
export const getCallAsteriskId = ({callInfo}: SharedState) => callInfo?.asteriskId;
export const getCallPlatformId = ({callInfo}: SharedState) => callInfo?.platformId;
export const getActiveCallPlatformId = ({callInfo}: SharedState) => callInfo?.activePlatformId;
export const getCommutationId = ({callInfo}: SharedState) => callInfo?.commutationId;

export const getMetaqueue = createSelector(
    [getQueue],
    getQueueWithoutSubcluster,
);

export const mapQueryToPhone = (query: {phone?: string}): string => (query.phone || '').replace(/[^0-9+]/g, '');
