import {customAlphabet} from 'nanoid';

const nanoid = customAlphabet('1234567890abcdef', 32);

export const createRequestIdHeader = () => {
    return {
        'X-CC-Operation-ID': nanoid(),
    };
};

export const createProjectHeader = (project: string | null): Record<string, string> => {
    if (!project) {
        return {};
    }
    return {
        'X-CC-Project': project,
    };
};
